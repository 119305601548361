// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-404-js": () => import("./../../../src/page/404.js" /* webpackChunkName: "component---src-page-404-js" */),
  "component---src-page-contact-index-js": () => import("./../../../src/page/contact/index.js" /* webpackChunkName: "component---src-page-contact-index-js" */),
  "component---src-page-index-js": () => import("./../../../src/page/index.js" /* webpackChunkName: "component---src-page-index-js" */),
  "component---src-page-legal-gdpr-index-js": () => import("./../../../src/page/legal/gdpr/index.js" /* webpackChunkName: "component---src-page-legal-gdpr-index-js" */),
  "component---src-page-legal-imprint-index-js": () => import("./../../../src/page/legal/imprint/index.js" /* webpackChunkName: "component---src-page-legal-imprint-index-js" */),
  "component---src-page-references-index-js": () => import("./../../../src/page/references/index.js" /* webpackChunkName: "component---src-page-references-index-js" */),
  "component---src-page-services-index-js": () => import("./../../../src/page/services/index.js" /* webpackChunkName: "component---src-page-services-index-js" */),
  "component---src-page-team-index-js": () => import("./../../../src/page/team/index.js" /* webpackChunkName: "component---src-page-team-index-js" */)
}

