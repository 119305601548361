module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Amann Massiv- & Betonbau","short_name":"Amann","start_url":"/","background_color":"#fff","theme_color":"#2CA067","display":"standalone","icon":"src/assets/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"4633ab2c0829f8cb746eca8707462499"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
